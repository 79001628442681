<template>
  <block
    :otherProps="otherProps"
    class="brick"
    :class="typeof data === 'string' && data.split('.').last()"
    :type="type_"
    :title="
      {
        header_2:
          t.monthly_report +
          ': ' +
          $root.period[1].replace(/,/g, '') +
          ' - ' +
          (t[$root.userflow.title] || ($root.userflow.title && $root.userflow.title.titleize())),
      }[title] || title
    "
    :data="data_"
    :metadata="metadata_"
    v-if="!error"
  >
    <template slot="title"><slot name="title"></slot></template>
    <slot></slot>
  </block>
  <div class="brick no-data block" :class="['block-' + (title || 'no-title')]" v-else>
    <h2>{{ t[title] || title }}</h2>
    <div style="font-size: 16px">No visualisation for this data</div>
  </div>
</template>

<script>
import oldBlock from './old-block.vue'

export default {
  components: {
    block: oldBlock,
  },
  props: ['type', 'title', 'data', 'metadata', 'otherProps'],
  data() {
    return {
      isTable: false,
      error: false,
    }
  },
  computed: {
    type_() {
      const map = {
        vbar: 'bar',
        hbar: 'pdf-bar',
        pie: 'pie',
        line: 'plot-line',
        'pdf-text': 'pdf-text',
        'pdf-table': 'pdf-table',
        'pdf-table-stacked': 'pdf-table-stacked',
      }
      if (map[this.type]) return map[this.type]
      if (
        (this.$root.userflow.table && this.$root.userflow.table[this.data_]) ||
        (this.$root.db.table && this.$root.db.table[this.data_]) ||
        (this.$root.config.table && this.$root.config.table[this.data_]) ||
        (this.data_ && typeof this.data_ !== 'string') ||
        (this.data_ && this.data_.includes(','))
      ) {
        this.isTable = true
        return 'pdf-table'
      }
      if (
        (this.$root.userflow && this.$root.userflow[this.data_]) ||
        (this.$root.db.text && this.$root.db.text[this.data_]) ||
        (this.$root.config.translation[this.$root.lang] &&
          this.$root.config.translation[this.$root.lang][this.data_]) ||
        ['orientation', 'comment'].includes(this.data_) ||
        (this.data_ && this.data.includes('computed') && this.data.includes('text'))
      )
        return 'pdf-text'
      if (/^block\./.test(this.data)) return this.data.replace('block.', 'pdf-')
      if (/^theme\./.test(this.data)) return 'pdf-image'
      // fall back sur pdf-text ?
      if (this.data_ && typeof this.data_ === 'string') return 'pdf-text'
      return this.type
    },
    data_() {
      let data = this.data
      if (typeof data === 'string' && data.startsWith('theme.'))
        return this.$root.cssvar[data.replace('theme.', '')].slice(4, -1)
      if (typeof data === 'string' && data.startsWith('nxpack.')) data = data.replace('nxpack.', '')
      if (typeof data === 'string' && data.startsWith('computed.')) data = this.$parent[data.replace('computed.', '')]
      if (typeof data === 'string' && data.startsWith('allocation.'))
        data = this.$parent.allocation[data.replace('allocation.', '')]
      if (typeof data === 'string' && data.startsWith('contribution.'))
        data = this.$parent.contribution[data.replace('contribution.', '')]
      if (this.type === 'line') {
        if (!data.keys()[0].match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) return Error('require timeserie')
        if (this.metadata_.limit) {
          data = data.filter((v, k) => k >= '' + (new Date().getFullYear() - this.metadata_.limit + 1))
        }
        return data
      }
      // Particular case of plot-bar with temporal data:
      if (this.type === 'vbar' && data.v().first() && (data.v().first().fund || data.v().first().fund === 0)) {
        return data
          .map((v, k) => ({ key: k, fund: v.fund, benchmark: v.benchmark }))
          .v()
          .sort('key')
          .slice(-this.metadata_.limit)
          .reduce((acc, v, k) => {
            acc[v.key] = v.benchmark ? { fund: v.fund, benchmark: v.benchmark } : { fund: v.fund }
            return acc
          }, {})
      }

      // Generic API for hbar / vbar / pdf-table
      const object_table = this.isTable === true && typeof data !== 'string' && !(data instanceof Array)
      if (['hbar', 'pie', 'vbar'].includes(this.type) || object_table) {
        const cash = data['Cash_Autres']
        const sorted_elements = data
          .filter((_, k) => k !== 'Cash_Autres')
          .filter(x => x)
          .filter(d => (['pie', 'hbar'].includes(this.type) && d > 0.0005) || this.type === 'vbar') // MAN: Exclude value in pie and hbar (todo refacor in mandarine not in bricks!)
          .map((v, k) => ({
            key: k,
            name: this.t[k] || k,
            value: v,
          }))
          .v()
          .sort(this.metadata_.sort)

        const others =
          sorted_elements.length > this.metadata_.limit ? sorted_elements.slice(this.metadata_.limit - 2) : {}
        const elements = sorted_elements
          .slice(
            0,
            this.metadata_.limit -
              (cash && this.metadata_.other ? 1 : 0) -
              (others.length && this.metadata_.other ? 1 : 0),
          )
          .concat(
            others.length && this.metadata_.other
              ? [
                  {
                    key: 'others',
                    name: `${this.t['other_' + this.title] || this.t['others'] + ' ' + (this.t[this.title] || this.title)}`,
                    value: others.map('value').sum(),
                  },
                ]
              : [],
          )
          .concat(
            cash && this.metadata_.other
              ? [
                  {
                    key: 'Cash_Autres',
                    name: `${this.t['Cash_Autres']}`,
                    value: cash,
                  },
                ]
              : [],
          )
          .filter(d => d.value)
        if (object_table && elements.length)
          return elements.reduce(
            (acc, v) => {
              acc.push([v.name, typeof v.value === 'object' ? v.value.v() : v.value].flatten())
              return acc
            },
            elements[0].value.keys().length >= 1 ? [[' ', elements[0].value.keys()].flatten()] : [],
          )
        if (this.type === 'vbar')
          return elements.reduce((acc, v) => {
            acc[v.key] = v.value
            return acc
          }, {})
        return elements
      }
      if (data instanceof Array) {
        return data.slice(0, this.metadata_.limit)
      }
      return data
    },
    metadata_() {
      const m = {
        format: null,
        sort: d => this.$root.months.indexOf(d.x),
        limit: Infinity,
        other: true,
        sort: '-value',
      }
      if (this.type && this.type === 'hbar') m.limit = 5
      if (this.type && this.type === 'pie') m.limit = 5
      return { ...m, ...this.metadata }
    },
  },
  errorCaptured(err, vm, info) {
    this.error = true
    console.log('error caught in brick', err)
  },
}
</script>
