<style></style>

<template lang="pug">
.pdf-table
  .line(:class="{ header: lnum === 0 && !line[0] }" v-for="(line, lnum) in data_")
    .cell(v-for="(cell, cnum) in line")
      div(v-html="display(cell, line, cnum, data_[0])" v-if="cell !== ''")
</template>

<script>
const filterIndicators = indicators => {
  return indicators.filter(indicator => {
    // Evaluate the condition and keep the row only if the second element is not null/undefined/empty
    const condition = eval(indicator[1])()
    return condition !== null && condition !== undefined && condition !== ''
  })
}
const isNumeral = str => {
  return !isNaN(str) && Number.isFinite(parseFloat(str))
}
export const additions = {}
export default {
  props: ['data', 'metadata', 'otherProps'],
  data() {
    const m = this.metadata || {}
    return {
      focus: [],
      format: m.format,
    }
  },
  computed: {
    data_() {
      let data =
        (this.$root.userflow.table && this.$root.userflow.table[this.data]) ||
        (this.$root.db.table && this.$root.db.table[this.data]) ||
        (this.$root.config.table && this.$root.config.table[this.data]) ||
        this.data ||
        []
      if (this.otherProps?.filterIndicators) {
        data = filterIndicators(data)
      }
      if (data && data.includes(','))
        return data.split(',').map(d => [d, this.$root.userflow[d] || this.$root.share[d] || d])
      if (typeof data === 'object' && typeof data[0] === 'string')
        return data.map(d => [d, this.$root.userflow[d] || this.$root.share[d] || d])

      return data
    },
  },
  methods: {
    input(lnum, cnum, value) {
      if (!window.set_debounced) window.set_debounced = set.debounce(2000)
      const key = this.$root.userflow.table.find(this.data)
      set_debounced(['userflows', this.$root.userflow.name, 'table', key, lnum, cnum].join('.'), value)
    },
    display(cell, line, cnum, header) {
      if (/^[a-zA-Z_]+\.[\w\d]+(\.[\w\d]+)?$/.test(cell)) {
        try {
          return window.format(cell.split('.')[0])(window.new_analytics(cell, this.otherProps))
        } catch (e) {
          return cell
        }
      }
      if (/=>/.test(cell))
        try {
          const value = function () {
            return eval(cell)()
          }.call($root)
          if (/mstar/.test(cell)) return window.format(this.format)(value)
          if (this.format) {
            return window.format(this.format)(value)
          } else {
            if (isNumeral(value)) return value
            // data is already coming formatted from db.js
            return this.t[value] || value
          }
        } catch (e) {
          console.log('ERROR', cell, e)
          return ''
        }
      return this.t[cell] || window.format(this.format || header[cnum])(cell)
    },
  },
}
</script>
